/** This component is used for links to pages that are generated by CMS */

import React from 'react';
import type { GatsbyLinkProps } from 'gatsby';
import { Link as GatsbyLink } from 'gatsby';
import { useLocation } from '@gatsbyjs/reach-router';
import { ensureTrailingSlash } from '../../util/ensureTranilingSlash';
import { isExternalLink } from '../../util/isExternalLink';

export type CMSLinkProps<TState> = React.PropsWithoutRef<
  GatsbyLinkProps<TState>
> & {
  isActive?: boolean;
  filterUrl?: boolean;
};

const applySingularUrl = (link: string) => {
  if (link.includes('tags/')) {
    link = link.replace('tags', 'tag');
  }

  if (link.includes('categories/')) {
    link = link.replace('categories', 'category');
  }

  // removes duplication in the url
  const segments = link.split('/');
  for (let i = 0; i < segments.length - 1; i++) {
    if (segments[i] === segments[i + 1]) {
      segments.splice(i + 1, 1);
    }
  }

  link = segments.join('/');

  return link;
};

const applyFilterUrl = (link: string, pathname: string) => {
  if (pathname.includes('/blog/') && link.includes('tag/')) {
    link = `blog/${link}`;
  }

  if (pathname.includes('/blog/') && link.includes('category/')) {
    link = `blog/${link}`;
  }

  if (pathname.includes('/work/') && link.includes('tag/')) {
    link = `work/${link}`;
  }

  if (pathname.includes('/work/') && link.includes('category/')) {
    link = `work/${link}`;
  }

  if (pathname.includes('/reports/') && link.includes('tag/')) {
    link = `reports/${link}`;
  }

  if (pathname.includes('/reports/') && link.includes('category/')) {
    link = `reports/${link}`;
  }

  return link;
};

export default function CMSLink<TState>({
  to,
  isActive,
  filterUrl = true,
  children,
  ...props
}: CMSLinkProps<TState>) {
  const location = useLocation();

  if (props.target === '_blank') {
    return (
      <a {...props} href={to} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  /* https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links */
  if (isExternalLink(to)) {
    return (
      <a {...props} href={to}>
        {children}
      </a>
    );
  }

  const singularUrl = applySingularUrl(to);
  const finalUrl = filterUrl
    ? applyFilterUrl(singularUrl, location.pathname)
    : singularUrl;

  return (
    <GatsbyLink<TState> {...props} to={`/${ensureTrailingSlash(finalUrl)}`}>
      {children}
    </GatsbyLink>
  );
}
