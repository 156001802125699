import React, { FC, ReactNode } from 'react';
import {
  StyledLink,
  StyledAnchor,
  StyledLinkButton,
} from './CallToActionLink.styles';

export type Size = 'sm' | 'md';

export interface CommonProps {
  size: Size;
  isSelected?: boolean;
  dataTestId?: string;
  selected?: boolean;
  selectable?: boolean;
  children?: ReactNode;
  rel?: string;
  to?: string;
}

export type CallToActionLinkProps = CommonProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type CallToActionLinkButtonProps = CommonProps &
  Omit<React.AnchorHTMLAttributes<HTMLButtonElement>, 'type'> & {
    type: 'button' | 'submit' | 'reset' | undefined;
    disabled: boolean;
  };

const formatString = (str: string): string => {
  return str.trim().toLowerCase().replace(/\s+/g, '-');
};

export const CallToActionLink: FC<CallToActionLinkProps> = ({
  size,
  isSelected,
  dataTestId,
  selected,
  selectable,
  children,
  rel,
  to,
  ...rest
}) => {
  const id = typeof children === 'string' ? formatString(children) : '';

  return to ? (
    <StyledLink size={size} selected={isSelected} to={to} {...rest} id={id}>
      {children}
    </StyledLink>
  ) : (
    <StyledAnchor size={size} selected={isSelected} {...rest} id={id}>
      {children}
    </StyledAnchor>
  );
};

export const CallToActionLinkButton: FC<CallToActionLinkButtonProps> = ({
  size,
  isSelected,
  dataTestId,
  selected,
  selectable,
  children,
  rel,
  type,
  disabled,
  ...rest
}) => {
  return (
    <StyledLinkButton
      type={type}
      size={size}
      selected={isSelected}
      disabled={disabled}
      {...rest}
    >
      {children}
    </StyledLinkButton>
  );
};

export default CallToActionLink;
